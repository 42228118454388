<template>
  
    <Navbar />
    <div class="cont_principal">
      <div class="cont_error">
        <!-- <h1>Teşekkürler</h1> -->
        <!-- <p>Anketimize katılıp, gelişmemize yardımcı olduğunuz için teşekkür ederiz.</p> -->
        <Card :v-if="ShowCard" class="centered" style="width: 70%; background-color: #f8f8f8;">
            <template #title>
                <div class="p-d-flex p-jc-center"><i class="pi pi-check-circle"
                        :style="{ fontSize: '5rem', color: 'var(--green-500)' }"></i></div>
            </template>
            <template #content>
                <div class="p-grid">
                    <div class="p-col-12 p-d-flex p-jc-center">
                        <div class="p-text-center p-text-bold">Anket Tamamlanmıştır </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-jc-center">
                        <div class="p-text-center p-text-bold">Bizi tercih ettiğiniz için teşekkür ederiz. </div>
                    </div>
                    <Divider />
                    <div class="p-col-12 p-d-flex p-jc-center p-mt-5">
                        <img class="" src="../../../assets/logo/logo1.png" style="width: 15vw;" />
                    </div>
                </div>
            </template>
        </Card>
      </div>
      <div class="cont_aura_1"></div>
      <div class="cont_aura_2"></div>
    </div>
  </template>
  <script>
  
  import Navbar from "../navbar.vue";
  export default {
    data() {
      return {
      };
    },
    methods: {
      
    },
    mounted(){
      document.querySelector('.cont_principal').className= "cont_principal cont_error_active";  
    },
    components: {
      Navbar,
    },
    beforeUnmount() {
    },
  };
  </script>
  <style scoped>
  * {
    margin:0px auto;
    padding: 0px;
  text-align:center;
  }
  body {
    background-color: #D4D9ED;
  }
  .cont_principal {
  position: absolute;  
    width: 100%;
    height: 100%;
  overflow: hidden;
  }
  .cont_error {
  position: absolute;
    width: 100%;
    height: 300px;
    top: 50%;
    margin-top:-150px;
  }
  
  .cont_error > h1  {
   font-family: 'Lato', sans-serif;  
  font-weight: 400;
    font-size:150px;
  color:#c283ec5b;
  position: relative;
  left:-100%;
  transition: all 0.5s;
  }
  
  
  .cont_error > p  {
   font-family: 'Lato', sans-serif;  
  font-weight: 300;
    font-size:24px;
    letter-spacing: 5px;
  color:#9294AE;
  position: relative;
  left:100%;
  transition: all 0.5s;
      transition-delay: 0.5s;
  -webkit-transition: all 0.5s;
   -webkit-transition-delay: 0.5s;
  }
  
  .cont_aura_1 {
    position:absolute;
    width:300px;
    height: 120%;
  top:25px;
  right: -340px;
    background-color: #8a65df;
  box-shadow: 0px 0px  60px  20px  rgba(137,100,222,0.5);
  -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
  
  .cont_aura_2 {
    position:absolute;
    width:100%;
    height: 300px;
  right:-10%;
  bottom:-301px;
   background-color: #5d4591;
  box-shadow: 0px 0px 60px 10px rgba(131, 95, 214, 0.5),0px 0px  20px  0px  rgba(0,0,0,0.1);
    z-index:5;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  }
  
  .cont_error_active > .cont_error > h1 {
    left:0%;
  }
  .cont_error_active > .cont_error > p {
    left:0%;
  }
  
  .cont_error_active > .cont_aura_2 {
      animation-name: animation_error_2;
      animation-duration: 4s;
    animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-direction: alternate;
  transform: rotate(-20deg);    
  }
  .cont_error_active > .cont_aura_1 {
   transform: rotate(20deg);
    right:-170px;
      animation-name: animation_error_1;
      animation-duration: 4s;
    animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-direction: alternate;
  }
  
  @-webkit-keyframes animation_error_1 {
    from {
      -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
    }
    to {  -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
    }
  }
  @-o-keyframes animation_error_1 {
    from {
      -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
    }
    to {  -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
    }
  
  }
  @-moz-keyframes animation_error_1 {
    from {
      -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
    }
    to {  -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
    }
  
  }
  @keyframes animation_error_1 {
    from {
      -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
    }
    to {  -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
    }
  }
  
  
  @-webkit-keyframes animation_error_2 {
    from { -webkit-transform: rotate(-15deg); 
    transform: rotate(-15deg);
    }
    to { -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
    }
  }
  @-o-keyframes animation_error_2 {
    from { -webkit-transform: rotate(-15deg); 
    transform: rotate(-15deg);
    }
    to { -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
    }
  }
  
  @-moz-keyframes animation_error_2 {
    from { -webkit-transform: rotate(-15deg); 
    transform: rotate(-15deg);
    }
    to { -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
    }
  }
  @keyframes animation_error_2 {
    from { -webkit-transform: rotate(-15deg); 
    transform: rotate(-15deg);
    }
    to { -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
    }
  }
  
  
  </style>